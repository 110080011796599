import React from "react";
import Panorama from "../components/Panorama";

const ThreeD = () => {
  return (
    <div class="overflow-hidden">
      <Panorama src="/assets/weconet_panorama1.png" />
    </div>
  );
};

export default ThreeD;
